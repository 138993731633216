<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Laporan SIP </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <!-- <div class="as-col col-md-3">
											<div class="form-group">
												<b for="">Kelompok Jenis Tanaman</b>
												<v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected_tanaman" placeholder="Kelompok Jenis Tanaman..."></v-select>
											</div>
										</div>
										<div class="as-col col-md-3">
											<div class="form-group">
												<b for="">Sub Kelompok Jenis Tanaman</b>
												<v-select :options="kelompok_selected_tanaman.tanaman_kategori_sub" label="tks_nama" v-model="subkelompok_selected_tanaman" placeholder="Sub Kelompok Jenis Tanaman..."></v-select>
											</div>
										</div> -->
                  <div class="as-col col-md-3">
                    <div class="form-group">
                      <b for="">Jenis Tanaman</b>
                      <v-select
                        :options="list_tanaman"
                        label="t_nama"
                        v-model="tanaman_selected"
                        :value="$store.myValue"
                        placeholder="Jenis Tanaman..."
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Perusahaan</b>
                      <v-select
                        :options="list_perusahaan"
                        label="p_nama"
                        v-model="perusahaan_selected"
                        :value="$store.myValue"
                        placeholder="Perusahaan..."
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Negara Tujuan</b>
                      <v-select
                        :options="list_negara"
                        label="n_nama"
                        v-model="negara"
                        placeholder="Negara..."
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Tempat Pengeluaran</b>
                      <v-select
                        :options="list_pelabuhan"
                        label="pl_nama"
                        v-model="pelabuhan"
                        placeholder="Tempat Pemasukan..."
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                        placeholder="Tanggal Dari..."
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                        placeholder="Tanggal Sampai..."
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Cari</b>
                      <input
                        type="text"
                        v-model="search"
                        class="form-control"
                        placeholder="Cari..."
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-5 d-flex justify-content-start"
                    style="margin-bottom: 10px; gap: 10px"
                  >
                    <div
                      class="btn btn-sm btn-primary"
                      v-on:click="filterData()"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </div>
                    <div class="btn btn-sm btn-info" v-on:click="resetData()">
                      <i class="fa fa-refresh"></i> Reset
                    </div>
                    <div class="btn btn-sm btn-success" v-on:click="getExcel">
                      <i class="fa fa-download"></i> Excel
                    </div>

                    <div
                      class="btn btn-sm btn-danger"
                      v-on:click="modalFilterPDF = true"
                    >
                      <i class="fa fa-download"></i> {{ buttonLabel }}
                    </div>

                    <!-- <div class="col-md-7 text-right">
													<router-link :to="{name: 'AddLaporanPemohonRealisasi'}" class="btn btn-info btn-sm"><i class="fa fa-plus"></i> Tambah Laporan Realisasi</router-link>
												</div> -->
                  </div>
                </div>
              </div>
            </div>
            <CRow>
              <CCol sm="12">
                <template>
                  <div v-if="show_tables">
                    <table
                      class="table table-sm table-bordered table-responsive"
                    >
                      <thead class="bg-dark text-white">
                        <tr>
                          <th rowspan="2">No</th>
                          <th rowspan="2">NIB</th>
                          <th rowspan="2">Nama Perusahaan</th>
                          <th rowspan="2">NPWP</th>
                          <th rowspan="2">Alamat Perusahaan</th>
                          <th rowspan="2">Nomor SIP</th>
                          <th rowspan="2">Tanggal SIP</th>
                          <th rowspan="2">Kategori Jenis Tanaman</th>
                          <th rowspan="2">Sub Kategori Jenis Tanaman</th>
                          <th rowspan="2">Jenis Tanaman</th>
                          <th rowspan="2">Nama Latin</th>
                          <th rowspan="2">Jumlah Varietas</th>
                          <th rowspan="2">Satuan</th>
                          <th colspan="3">Volume</th>
                          <th rowspan="2">Negara Tujuan</th>
                          <th rowspan="2">Tempat Pengeluaran</th>
                          <th rowspan="2">Tujuan Pengeluaran</th>
                          <th rowspan="2">Kode HS</th>
                        </tr>
                        <tr>
                          <th>SIP</th>
                          <th>Realisai</th>
                          <th>Nilai (USD)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-if="loadingTable">
                            <td colspan="6">
                              <i class="fa fa-spin fa-refresh"></i> Loading...
                            </td>
                          </tr>
                          <tr
                            v-else-if="!loadingTable && laporan_sip.length <= 0"
                          >
                            <td colspan="6">
                              <i class="fa fa-times"></i> Data Belum Tersedia
                            </td>
                          </tr>

                          <tr
                            v-for="(
                              value_laporan_sip, key_laporan_sip
                            ) in laporan_sip"
                            v-else
                            :key="key_laporan_sip"
                          >
                            <td>
                              <template>
                                <div v-if="key_laporan_sip + 1 == 10">
                                  {{ curent_page }}0
                                </div>
                                <div v-else>
                                  <template>
                                    <span v-if="curent_page - 1 != 0">
                                      {{ curent_page - 1
                                      }}{{ key_laporan_sip + 1 }}
                                    </span>
                                    <span v-else>
                                      {{ key_laporan_sip + 1 }}
                                    </span>
                                  </template>
                                </div>
                              </template>
                            </td>
                            <td>{{ value_laporan_sip.nib }}</td>
                            <td>{{ value_laporan_sip.p_nama }}</td>
                            <td>{{ value_laporan_sip.p_npwp }}</td>
                            <td>{{ value_laporan_sip.p_alamat }}</td>
                            <td>{{ value_laporan_sip.pr_nomor_sk }}</td>
                            <td>{{ value_laporan_sip.pr_tanggal_sk }}</td>
                            <td>
                              {{
                                value_laporan_sip.tanaman.tanaman_kategori_nama
                              }}
                            </td>
                            <td>
                              {{
                                value_laporan_sip.tanaman
                                  .tanaman_kategori_sub_nama
                              }}
                            </td>
                            <td>
                              {{ value_laporan_sip.tanaman.t_nama }}
                            </td>
                            <td>
                              {{ value_laporan_sip.tanaman.t_nama_latin }}
                            </td>
                            <td>{{ value_laporan_sip.jumlah_varietas }}</td>
                            <td>{{ value_laporan_sip.satuan.s_nama }}</td>
                            <td>{{ value_laporan_sip.jumlah_disetujui }}</td>
                            <td>{{ value_laporan_sip.jumlah_disetujui }}</td>
                            <td>{{ value_laporan_sip.jumlah_nilai_total }}</td>
                            <td>
                              {{ value_laporan_sip.n_inisial }} -
                              {{ value_laporan_sip.n_nama }}
                            </td>
                            <td>
                              {{ value_laporan_sip.pl_code }} -
                              {{ value_laporan_sip.pl_nama }}
                            </td>
                            <td>
                              {{
                                value_laporan_sip.tujuan_penggunaan_benih_nama
                              }}
                            </td>
                            <td>{{ value_laporan_sip.pb_kode_hs }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <nav aria-label="pagination">
                      <ul class="pagination justify-content-start">
                        <!-- <li v-for="(page, key_page) in paging" v-bind:key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
														<div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
															<div v-html="page.label"></div>
														</div>
													</li> -->
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div v-else>
                    <template>
                      <div v-if="loadingTable">
                        <i class="fa fa-spin fa-refresh"></i> Loading...
                      </div>
                      <div class="alert alert-info" v-else>
                        Untuk menampilkan data, filter data terlebih dahulu
                      </div>
                    </template>
                  </div>
                </template>
              </CCol>
            </CRow>
            <br />
            <!-- form Filter PDF -->
            <template>
              <div>
                <CModal
                  title="Filter data"
                  color="btn-primary"
                  :show.sync="modalFilterPDF"
                  size="md"
                >
                  <CRow>
                    <CCol md="12">
                      <div class="form-group">
                        <b for="">Tgl Dari</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="start_pdf"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                      <div class="form-group">
                        <b for="">Tgl Sampai</b>
                        <vuejs-datepicker
                          input-class="form-control no-readonly"
                          v-model="end_pdf"
                          :disabled-dates="disabledDates"
                          format="dd MMM yyyy"
                        ></vuejs-datepicker>
                      </div>
                    </CCol>
                  </CRow>
                  <template #footer>
                    <CButton @click="modalFilterPDF = false" color="secondary"
                      >Tutup</CButton
                    >
                    <CButton
                      v-on:click="getPDFLink()"
                      color="primary"
                      :disabled="isDownloading"
                      >{{ buttonLabel }}</CButton
                    >
                  </template>
                </CModal>
              </div>
            </template>

            <!-- form Filter PDF selesai -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const datalaporan = [];
export default {
  name: "LaporanPengeluaranSIP",
  components: {
    vuejsDatepicker,
  },
  data() {
    return {
      show_tables: false,
      laporan_sip: datalaporan,
      paging: [],
      show_data_to: "",
      show_data_from: "",
      total_data: "",
      curent_page: "",
      loadingTable: false,
      session_data: JSON.parse(this.session),
      is_pemasukan: "",
      start: "",
      end: "",
      status: "",
      excel_url: "",
      list_perusahaan: "",
      perusahaan_selected: {
        nib: "semua",
        p_nama: "-- Pilih Data --",
      },

      search: "",
      filter_status: [
        {
          value: "proses",
          label: "Proses Verifikasi",
        },
        {
          value: "menunggu perbaikan",
          label: "Menunggu Perbaikan",
        },
        {
          value: "selesai",
          label: "Selesai",
        },
        {
          value: "dibatalkan",
          label: "Dibatalkan",
        },
      ],
      filter_tipe: [
        {
          value: "1",
          label: "Pemasukkan Benih Tanaman",
        },
        {
          value: "0",
          label: "Pengeluaran Benih Tanaman",
        },
      ],

      list_negara: [],
      negara: {
        id: "semua",
        n_nama: "-- Pilih Data --",
      },
      list_pelabuhan: [],
      pelabuhan: {
        id: "semua",
        pl_nama: "-- Pilih Data --",
      },
      list_tanaman: [],
      kelompok_selected_tanaman: [],
      subkelompok_selected_tanaman: [],
      tanaman_selected: {
        id: "semua",
        t_nama: "-- Pilih Data --",
      },

      tahun_filter: this.$route.query.tahun,
      negara_filter: this.$route.query.negara,
      nib_filter: this.$route.query.nib,
      tanaman_filter: this.$route.query.tanaman,

      // for filter pdf
      buttonLabel: "Export PDF",
      isDownloading: false,
      modalFilterPDF: false,
      start_pdf: "",
      end_pdf: "",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        from: new Date(8640000000000000),
        customPredictor: (date) => {
          if (!this.start_pdf) {
            return false;
          }
          const start = new Date(this.start_pdf);
          const end = new Date(this.start_pdf);
          end.setDate(start.getDate() + 31);

          return date < start || date > end;
        },
      },
    };
  },
  methods: {
    toPage(url) {
      this.loadingTable = true;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_lapsi = response.data;
          var res_lapsip_data = res_lapsi.data;
          console.log(res_lapsip_data);
          if (res_lapsi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_lapsi.data.message,
            });
          } else {
            this.loadingTable = false;
            this.laporan_sip = res_lapsip_data.list_data.data;
            this.curent_page = res_lapsip_data.list_data.current_page;
            this.excel_url = res_lapsip_data.excel_url;
            this.paging = res_lapsip_data.list_data.links;
          }
        });
    },
    resetData() {
      this.start = "";
      this.end = "";
      this.search = "";
      this.negara = {
        id: "semua",
        n_nama: "-- Pilih Data --",
      };
      this.pelabuhan = {
        id: "semua",
        pl_nama: "-- Pilih Data --",
      };
      this.kelompok_selected_tanaman = [];
      this.subkelompok_selected_tanaman = [];
      this.tanaman_selected = {
        id: "semua",
        t_nama: "-- Pilih Data --",
      };
      this.perusahaan_selected = {
        nib: "semua",
        p_nama: "-- Pilih Data --",
      };
    },
    filterData() {
      this.loadingTable = true;
      axios
        .get(this.apiLink + "api/laporan/laporan_sip", {
          params: {
            kelompok: this.kelompok_selected_tanaman?.id,
            sub_kelompok: this.subkelompok_selected_tanaman?.id,
            tanaman: this.tanaman_selected?.id,
            is_pemasukan: "false",
            start: this.start,
            end: this.end,
            search: this.search,
            nib: this.perusahaan_selected?.nib,
            negara: this.negara?.id,
            pelabuhan: this.pelabuhan?.id,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_lapsi = response.data;
          var res_lapsip_data = res_lapsi.data;
          this.loadingTable = false;
          this.show_tables = true;
          if (res_lapsi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_lapsi.data.message,
            });
          } else {
            this.laporan_sip = res_lapsip_data.list_data.data;
            this.curent_page = res_lapsip_data.list_data.current_page;
            this.excel_url = res_lapsip_data.excel_url;
            this.paging = res_lapsip_data.list_data.links;
          }

          console.log("show table state filter: ", this.show_tables);
          console.log("loading state  filter: ", this.loadingTable);
        });
    },
    getExcel() {
      // alert(this.excel_url);
      // return false;
      var win = window.open(this.excel_url, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getPDFLink() {
      if (this.start_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      } else if (this.end_pdf === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan masukan tanggal filter",
        });
        return;
      }

      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "";
      if (this.perusahaan_selected.nib != "semua") {
        fileName =
          "Laporan Pengeluaran SIP " + this.perusahaan_selected.p_nama + ".pdf";
      } else {
        fileName = "Laporan Pengeluaran SIP.pdf";
      }

      axios
        .get(this.apiLink + "api/pdf-laporan-sip", {
          params: {
            kelompok: this.kelompok_selected_tanaman?.id,
            sub_kelompok: this.subkelompok_selected_tanaman?.id,
            tanaman: this.tanaman_selected?.id,
            is_pemasukan: "false",
            start: this.start_pdf,
            end: this.end_pdf,
            search: this.search,
            nib: this.perusahaan_selected?.nib,
            negara: this.negara?.id,
            pelabuhan: this.pelabuhan?.id,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
  created() {
    // Get Data From Redirect Dashboard Horti
    if (
      this.tahun_filter != "" &&
      this.tahun_filter != null &&
      // this.negara_filter != '' && this.negara_filter != null &&
      // this.nib_filter != '' && this.nib_filter != null
      this.tanaman_filter != "" &&
      this.tanaman_filter != null
    ) {
      this.loadingTable = true;
      axios
        .get(this.apiLink + "api/laporan/laporan_sip", {
          params: {
            tanaman: this.tanaman_filter,
            negara: this.negara_filter,
            nib: this.nib_filter,
            is_pemasukan: "false",
            tahun: this.tahun_filter,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_lapsi = response.data;
          var res_lapsip_data = res_lapsi.data;
          this.loadingTable = false;
          this.show_tables = true;
          if (res_lapsi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_lapsi.data.message,
            });
          } else {
            this.laporan_sip = res_lapsip_data.list_data.data;
            this.curent_page = res_lapsip_data.list_data.current_page;
            this.excel_url = res_lapsip_data.excel_url;
            this.paging = res_lapsip_data.list_data.links;
          }
        });
    }
    // Get Master Tanaman
    axios
      .get(this.apiLink + "api/master/perusahaan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan = response.data;
        var res_perusahaan_data = res_perusahaan.data;
        if (res_perusahaan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan.data.message,
          });
        } else {
          this.list_perusahaan = res_perusahaan_data.master;
          this.list_perusahaan.unshift({
            nib: "semua",
            p_nama: "-- Pilih Data --",
          });
          // console.log(res_tanaman_data.master);
        }
      });
    axios.get(this.apiLink + "api/master/list_tanaman").then((response) => {
      var res_tanaman = response.data;
      var res_tanaman_data = res_tanaman.data;
      if (res_tanaman.meta.code != "200") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res_tanaman.data.message,
        });
      } else {
        this.list_tanaman = res_tanaman_data.master;
        this.list_tanaman.unshift({
          id: "semua",
          t_nama: "-- Pilih Data --",
        });
        // console.log(res_tanaman_data.master);
      }
    });
    axios
      .get(this.apiLink + "api/master/negara", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_negara = response.data;
        var res_negara_data = res_negara.data;
        if (res_negara.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_negara.data.message,
          });
        } else {
          this.list_negara = res_negara_data.master;
          this.list_negara.unshift({
            id: "semua",
            n_nama: "-- Pilih Data --",
          });
          // console.log(res_tanaman_data.master);
        }
      });
    axios
      .get(this.apiLink + "api/master/pelabuhan", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_pelabuhan = response.data;
        var res_pelabuhan_data = res_pelabuhan.data;
        if (res_pelabuhan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_pelabuhan.data.message,
          });
        } else {
          this.list_pelabuhan = res_pelabuhan_data.master;
          this.list_pelabuhan.unshift({
            id: "semua",
            pl_nama: "-- Pilih Data --",
          });
          // console.log(res_tanaman_data.master);
        }
      });
  },
  mounted() {
    $(".no-readonly").prop("readonly", false);
  },
  watch: {
    start_pdf(val) {
      if (val) {
        const start = new Date(val);
        const end = new Date(start);
        end.setDate(start.getDate() + 31);

        this.disabledDates = {
          ranges: [
            {
              from: new Date(-8640000000000000),
              to: new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate()
              ),
            },
            {
              from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
              to: new Date(8640000000000000),
            },
          ],
        };
      }
    },
  },
};
</script>